<template>
    <div
        id="listing-filters"
        class="relative backdrop-blur-md bg-opacity-75 z-10 bg-white pt-8 flex flex-col gap-2 w-full"
    >
        <h3 class="my-4">What job are you looking for?</h3>
        <div class="flex flex-col md:flex-row gap-6 items-center">
            <!-- Search -->
            <div class="relative flex-1 w-full">
                <label
                    for="name"
                    class="absolute -top-3 left-2 inline-block bg-white px-1 tracking-wider text-md font-gothic text-gray-900"
                    >Search</label
                >
                <input
                    v-model="searchQuery"
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-lg sm:leading-6"
                    placeholder="Sales job"
                />
            </div>
            <!-- Salary Range -->
            <!-- <div class="w-full flex-1 px-2">
            <ListingSalaryRangeSlider v-model="selectedSalaryRange" :salary-ranges="filterOptions.salaryRanges" />
        </div> -->
        </div>
        <div
            class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 justify-center md:justify-start lg:justify-between gap-x-4 gap-y-2"
        >
            <!-- Category Filter -->
            <ListingsFiltersCombo
                class="w-full min-w-fit flex-1"
                :options="filterOptions.categories"
                v-model="selectedFilterIds.categories"
                label="Category"
            />

            <!-- Experience Filter -->
            <ListingsFiltersCombo
                class="w-full min-w-fit flex-1"
                :options="filterOptions.experiences"
                v-model="selectedFilterIds.experiences"
                label="Experience"
            />

            <!-- Commitment Type Filter -->
            <ListingsFiltersCombo
                class="w-full min-w-fit flex-1"
                :options="filterOptions.commitments"
                v-model="selectedFilterIds.commitments"
                label="Job Type"
            />

            <!-- Remote Status Filter -->
            <ListingsFiltersCombo
                class="w-full min-w-fit flex-1"
                :options="filterOptions.remoteStatuses"
                v-model="selectedFilterIds.remoteStatuses"
                label="Remote Status"
            />

            <!-- Benefits Filter -->
            <ListingsFiltersCombo
                class="w-full min-w-fit flex-1"
                :options="filterOptions.benefits"
                v-model="selectedFilterIds.benefits"
                label="Benefits"
            />
        </div>

        <!-- Active filters -->
        <ListingsFiltersActive
            v-if="!filterOptionsSelectedEmpty"
            :active-filters="filterOptionsSelected"
            @reset-filters="resetFilters"
            @remove-filter="removeFilter"
        />
    </div>
</template>

<script setup>
import ListingsFiltersCombo from "@/Components/Guest/Listings/ListingsFiltersCombo.vue";
import ListingSalaryRangeSlider from "@/Components/Guest/Listings/ListingSalaryRangeSlider.vue";
import ListingsFiltersActive from "@/Components/Guest/Listings/ListingsFiltersActive.vue";
import { computed, inject, ref, watch } from "vue";
import { router } from "@inertiajs/vue3";
import debounce from "lodash/debounce";

// filters from controller/query string
const filters = inject("filters");

// filter options
const filterOptions = inject("filterOptions");

// default salary range
const defaultSalaryRange = 2;

// function to filter selected filterOptions
const filterSelected = (selectedFiltersInRoute, availableOptions) =>
    availableOptions.filter(({ id }) =>
        selectedFiltersInRoute.includes(id.toString()),
    );

// selected filter options
const filterOptionsSelected = computed(() => ({
    categories: filters.value.categories
        ? filterSelected(
              filters.value.categories,
              filterOptions.value.categories,
          )
        : [],
    experiences: filters.value.experiences
        ? filterSelected(
              filters.value.experiences,
              filterOptions.value.experiences,
          )
        : [],
    commitments: filters.value.commitments
        ? filterSelected(
              filters.value.commitments,
              filterOptions.value.commitments,
          )
        : [],
    remoteStatuses: filters.value.remoteStatuses
        ? filterSelected(
              filters.value.remoteStatuses,
              filterOptions.value.remoteStatuses,
          )
        : [],
    benefits: filters.value.benefits
        ? filterSelected(filters.value.benefits, filterOptions.value.benefits)
        : [],
}));

// function to check if filterOptionsSelected is empty
const filterOptionsSelectedEmpty = computed(() => {
    return Object.values(filterOptionsSelected.value).every(
        (filter) => filter.length === 0,
    );
});

// state and selected filter IDs
const searchQuery = ref(filters.value.search);
const selectedSalaryRange = ref(
    filters.value.salaryRange
        ? parseInt(filters.value.salaryRange)
        : defaultSalaryRange,
);

const selectedFilterIds = ref({
    categories: filterOptionsSelected.value.categories,
    experiences: filterOptionsSelected.value.experiences,
    commitments: filterOptionsSelected.value.commitments,
    remoteStatuses: filterOptionsSelected.value.remoteStatuses,
    benefits: filterOptionsSelected.value.benefits,
});

const currentRoute = inject("currentRoute");

// send request to the controller when the filters change
watch(
    [searchQuery, selectedSalaryRange, selectedFilterIds],
    debounce(([searchQuery, salaryRange, selectedFilterIds]) => {
        router.get(
            currentRoute.value,
            {
                search: searchQuery ? searchQuery : null,
                // salaryRange: salaryRange,
                categories: selectedFilterIds.categories
                    ? selectedFilterIds.categories.map(({ id }) => id)
                    : null,
                experiences: selectedFilterIds.experiences
                    ? selectedFilterIds.experiences.map(({ id }) => id)
                    : null,
                commitments: selectedFilterIds.commitments
                    ? selectedFilterIds.commitments.map(({ id }) => id)
                    : null,
                remoteStatuses: selectedFilterIds.remoteStatuses
                    ? selectedFilterIds.remoteStatuses.map(({ id }) => id)
                    : null,
                benefits: selectedFilterIds.benefits
                    ? selectedFilterIds.benefits.map(({ id }) => id)
                    : null,
            },
            { preserveState: true, preserveScroll: true },
        );
    }, 300),
    { deep: true },
);

// create method for event that resets the filters and search
const resetFilters = () => {
    searchQuery.value = null;
    selectedSalaryRange.value = defaultSalaryRange;
    selectedFilterIds.value.categories = [];
    selectedFilterIds.value.experiences = [];
    selectedFilterIds.value.commitments = [];
    selectedFilterIds.value.remoteStatuses = [];
    selectedFilterIds.value.benefits = [];
};

// function to remove specific filter
const removeFilter = ({ type, id }) => {
    selectedFilterIds.value[type] = selectedFilterIds.value[type].filter(
        (filter) => filter.id !== id,
    );
};

defineExpose({ resetFilters });
</script>
